import { useContext, useEffect, useState } from "react";
import Box from "../../../components/utility/box";
import { BillingFormWrapper } from "../../Profile/ProfileForm.styles";
import { Label } from "../../UsersCRUD/UsersCRUD.styles";
import learnMoreImg from '../../../assets/images/learnMoreImg.svg';
import CreateTaskFooter from "../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter";
import CreateTaskHeader from "../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader";
import { AppContext } from "../../../context/ContextProvider";
import api from "../../../api";
import oneClickListIcon from '../../../assets/images/oneClickList.svg';
import { Spin, Tooltip } from "antd";
import coinIcon from '@iso/assets/images/coin-icon.svg';
import { useNavigate } from "react-router-dom";
import { RemoveClusterFromLocal } from "../../../context/helper";
import { AlertContext } from "../../../context/alertContext";
import { CustomModal } from "../../Dashboard/CustomComponents/PageLoader.style";
import { LoadingOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars-2";
import LayoutWrapper from "../../../components/utility/layoutWrapper";
import { useFetchClusterData } from "../../Dashboard/CustomHooks/useFetchClusterData";


let boxStyle = {
    borderTop: "0",
    borderBottom: "1px solid #f2f2f2",
    borderLeft: "0",
    borderRight: "0",
    padding: "5px"
};

let labelStyle = {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.04px",
    textAlign: "left",
    color: "#1f1f1f",
};

let valueStyle = {
    fontFamily: "Nunito Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.03px",
    textAlign: "left",
    color: "#9b9b9b",
};

let learnMoreStyle = {
    flexGrow: "0",
    fontFamily: "Nunito Sans",
    fontSize: "14px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.71",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#45d1ff",
    marginTop: "1px"
};

let noteStyle = {
    fontFamily: "Nunito Sans",
    fontSize: "14px",
    fontWeight: "300",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.14",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#132f44"
};

export default function OneClickClusterDrawer({ data }) {
    const [defaultData, setDefaultData] = useState([
        {
            icon: oneClickListIcon,
            fieldName: "Cluster name",
            value: ""// or ""
        },
        {
            icon: oneClickListIcon,
            fieldName: "Region",
            value: ""// or ""
        },
        {
            icon: oneClickListIcon,
            fieldName: "Target Account",
            value: ""// or ""
        },
        {
            icon: oneClickListIcon,
            fieldName: "Environment",
            value: ""// or ""
        },
        {
            icon: oneClickListIcon,
            fieldName: "Availability Zones",
            value: ""// or ""
        },
        {
            icon: oneClickListIcon,
            fieldName: "Kubernetes Version",
            value: ""// or ""
        },
        {
            icon: oneClickListIcon,
            fieldName: "Addons",
            value: []// or ""
        },
    ]);

    const [costData, setCostData] = useState({});
    const { fetchClusterData } = useFetchClusterData();

    const { setOpenRightDrawer, oneClickCluster, setOneClickCluster, setIsOneClick, setToggleClusterOverviewLogs, setOpenModel, refreshBoardList, setRefreshBoardList } = useContext(AppContext);
    const [myClusterData, setMyClusterData] = useState(oneClickCluster);
    const { set_alertOptions } = useContext(AlertContext);
    const [isCreating, setIsCreating] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {
        if (Object.keys(myClusterData).length > 0) {
            defaultData[0].value = myClusterData.selectedCluster.display_name;
            defaultData[1].value = myClusterData.selectedCluster.cluster_data.region;
            defaultData[2].value = myClusterData.selectedCluster.target_account_id;
            defaultData[3].value = myClusterData.selectedCluster.cluster_data.environment;
            defaultData[4].value = myClusterData.selectedCluster.cluster_data.availability_zones;
            defaultData[5].value = myClusterData.selectedCluster.cluster_data.kubernetes_version;
            defaultData[6].value = myClusterData.selectedCluster.addons_list;
            setDefaultData([...defaultData]);
        }
    }, []);

    useEffect(() => {
        console.log("Enters...", myClusterData);
        if (myClusterData.selectedCluster.display_name && myClusterData.selectedCluster.display_name !== "") {
            console.log("Enters...1", myClusterData);
            let payload = {
                cluster_type: myClusterData.selectedCluster.cluster_data.environment,
                integration_id: myClusterData.selectedCluster.cluster_data.integration_id,
                azones: 1,
                region: myClusterData.selectedCluster.cluster_data.region,
                network_load_balancer: myClusterData?.selectedCluster.addons_list?.some((x) => x.name === "Nginx Ingress Controller").toString(),
                application_load_balancer: "false",
                pgl_enabled: "false",
            }
            api.post(process.env.REACT_APP_API_URI + "/api/cluster/cluster-cost/", payload)
                .then((res) => {
                    let data = res.data;
                    if (data.status) {
                        console.log("data in /cluster-cost :", data);
                        setCostData({
                            // title: pageHeadingsObj.clusters.createCluster.clusterPreview.title,
                            // subTitle: pageHeadingsObj.clusters.createCluster.clusterPreview.subTitle,
                            // buttonContent: pageHeadingsObj.clusters.createCluster.clusterPreview.buttonContent,
                            dataTransferCost: data.data.data_transfer_cost ? (data.data.data_transfer_cost === "Unknown" ? "-" : "$" + data.data.data_transfer_cost) : "$0",
                            eksClusterCost: data.data.cluster_cost ? "$" + data.data.cluster_cost + " /mo" : 0 + " /mo",
                            instanceTypeCost: data.data.instance_type_cost ? "$" + data.data.instance_type_cost + " /mo" : 0 + " /mo",
                            loadBalanceCost: data.data.load_balance_cost ? "$" + data.data.load_balance_cost + " /mo" : 0 + " /mo",
                            natGatewayCost: data.data.nat_gateway_cost ? "$" + data.data.nat_gateway_cost + " /mo" : 0 + " /mo",
                            storageCost: data.data.storage_cost ? "$" + data.data.storage_cost + " /mo" : 0 + " /mo",
                            totalCost: data.data.total_cluster_cost ? "$" + data.data.total_cluster_cost + " /mo" : 0 + " /mo",
                            fetching: false
                        });
                    } else {
                        let err; //formatErrors(data.error_details);
                        // setPageHeading({
                        //     ...pageHeadingsObj.clusters.createCluster.clusterPreview,
                        //     fetching: false,
                        // });
                    }
                })
                .catch((err) => {
                    console.log("err :", err);
                    // setPageHeading({
                    //     ...pageHeadingsObj.clusters.createCluster.clusterPreview,
                    //     fetching: false,
                    // });
                });
        }
    }, [myClusterData.selectedCluster?.display_name]);

    function toggleAlert(type, message, description) {
        console.log("Toggle alert called: ");
        set_alertOptions(alertOptions => ({
            status: true,
            type: type,
            message: message,
            description: description,
        }));

        // opacity=0 after 10sec
        setTimeout(() => {
            set_alertOptions(alertOptions => ({
                status: false,
                type: type,
                message: message,
                description: description,
            }));
        }, 5 * 1000);
    }

    const submitDetails = () => {
        setIsCreating(true);
        let payload =
            myClusterData.selectedCluster.integration_type === 1
                ? {
                    display_name: myClusterData.selectedCluster.display_name,
                    cluster_type: myClusterData.selectedCluster.cluster_data.environment,
                    account_id: myClusterData.selectedCluster.target_account_id,
                    integration: myClusterData.selectedCluster.integration_type,
                    region: myClusterData.region,
                    specifications: {
                        create_vpc: true,
                        vpc_id: undefined,
                        region: myClusterData.selectedCluster.cluster_data.region,
                        associate_public_ip_address: false,
                        private_subnets: [],
                        public_subnets: [],
                        tags: [],
                        availability_zones: [myClusterData.selectedCluster.cluster_data.availability_zones],
                        vpc_cidr: myClusterData.selectedCluster.cluster_data.vpc_cidr_block,
                        flow_log_cloudwatch_log_group_retention_in_days: "30",
                        cluster_log_retention_in_days: "30",
                        flow_log_max_aggregation_interval_in_seconds: "0",
                        cluster_enabled_log_types: "false",
                        cluster_endpoint_public_access: "true",//myClusterData.cluster_endpoint_public_access.toString(),//selectedCluster[clusterTabSelected].endpointPublicAccess.toString(),
                        cluster_endpoint_private_access: "true",
                        cluster_version: myClusterData.selectedCluster.cluster_data.kubernetes_version,
                        enable_flow_log: "false",
                        enabled_addons: myClusterData.selectedCluster.addons_list.map((val) => val.addon_id),
                        // TF_VAR_cert_manager_enabled: currentCluster[clusterTabSelected].addons.cert_manager_enabled.toString(),
                        // TF_VAR_enable_external_secrets: currentCluster[clusterTabSelected].addons.enable_external_secrets.toString(),
                        // TF_VAR_create_efs_storage_class: currentCluster[clusterTabSelected].addons.create_efs_storage_class.toString(),
                        //TF_VAR_enable_istio: "false",
                        //TF_VAR_enable_karpenter: currentCluster[clusterTabSelected].addons.enable_karpenter.toString(),

                        // TF_VAR_enable_ingress_nginx: currentCluster[clusterTabSelected].addons.nginx_ingress_controler.toString(),
                        // TF_VAR_enable_aws_load_balancer_controller: currentCluster[clusterTabSelected].addons.nginx_alb_controler.toString(),

                        // "TF_VAR_cert_manager_install_letsencrypt_http_issuers": currentCluster[clusterTabSelected].addons.cert_manager_install_letsencrypt_http_issuers.toString(),
                        // "TF_VAR_cert_manager_letsencrypt_email": currentCluster[clusterTabSelected].addons.cert_manager_letsencrypt_email,
                        // "TF_VAR_karpenter_provisioner_enabled": currentCluster[clusterTabSelected].addons.karpenter_provisioner_enabled.toString(),
                        pgl_enabled: "false",
                        pgl_specifications: null,
                        k8s_dashboard_specifications: null,
                        argoworkflow_specifications: myClusterData.selectedCluster.argoworkflow_specifications,
                        api: false,
                        audit: false,
                        authenticator: false,
                        scheduler: false,
                        controllerManager: false
                    },
                }
                : {
                    // display_name: selectedCluster.title && selectedCluster.title.trim(), //"g-21-3",
                    // cluster_type: clusterTabSelected, //"prod",
                    // account_id: selectedCluster.accountId, //"atmosly-access",
                    // integration: selectedCluster.integration, //3,
                    // region: selectedCluster.region, //"asia-south1",
                    // specifications: {
                    //     vpc_cidr:
                    //     selectedCluster[clusterTabSelected].vpcCidr &&
                    //     selectedCluster[clusterTabSelected].vpcCidr.trim(), //"10.1.0.0/16",
                    //     enable_flow_log:
                    //     selectedCluster[clusterTabSelected].vpcFlowLogs.toString(), //"true",
                    //     availability_zones: selectedCluster[clusterTabSelected].naz, // ["asia-south1-a","asia-south1-b"],
                    //     cluster_version: selectedCluster[clusterTabSelected].eks, //"1.25",
                    //     TF_VAR_enable_ingress_nginx:
                    //     selectedCluster[
                    //             clusterTabSelected
                    //         ].addons.enable_ingress_nginx.toString(), //"true",
                    //     TF_VAR_cert_manager_enabled:
                    //     selectedCluster[
                    //             clusterTabSelected
                    //         ].addons.cert_manager_enabled.toString(), //"true",
                    //     TF_VAR_enable_external_secrets:
                    //     selectedCluster[
                    //             clusterTabSelected
                    //         ].addons.enable_external_secrets.toString(), //"true",
                    //     TF_VAR_enable_keda:
                    //     selectedCluster[
                    //             clusterTabSelected
                    //         ].addons.enable_keda.toString(), //"true",
                    //     // "TF_VAR_default_np_max_count": currentCluster[clusterTabSelected].instanceTypeMax,//"10",
                    //     // "TF_VAR_default_np_min_count": currentCluster[clusterTabSelected].instanceTypeMin,//"0",
                    //     TF_VAR_cluster_resource_labels:
                    //     selectedCluster[clusterTabSelected].tags.length === 1
                    //             ? selectedCluster[clusterTabSelected].tags[0].name === "" &&
                    //             selectedCluster[clusterTabSelected].tags[0].value === ""
                    //                 ? []
                    //                 : selectedCluster[clusterTabSelected].tags
                    //             : selectedCluster[clusterTabSelected].tags, //[
                    //     //     {
                    //     //         "name": "Creator",
                    //     //         "value": "Aadesh"
                    //     //     },
                    //     //     {
                    //     //         "name": "Device",
                    //     //         "value": "Laptop"
                    //     //     }
                    //     // ]
                };

        console.log("Payload: ", payload);

        api.post(process.env.REACT_APP_API_URI + "/api/cluster/", payload)
            .then((res) => {
                let data = res.data;
                if (data.status) {
                    console.log("Success");
                    setOneClickCluster({});
                    RemoveClusterFromLocal();
                    setOpenRightDrawer({
                        drawerType: false,
                        drawerProps: {
                            data: null
                        },
                    });
                    setIsCreating(false);
                    setOpenModel({
                        modalType: 'CONFIRM_ACTION',
                        modalProps: {
                            data: "",
                            title: "Cluster Creation Initialising",
                            subTitle: <div>
                                <span>Congratulations! Your cluster creation process has started, it will take about 20 minutes for it to be</span>
                                <span style={{ color: "#009428" }}>&nbsp;ACTIVE.</span>
                            </div>,
                            cancelButton: "Cancel",
                            saveButton: "Navigate to Projects",
                            onConfirm: () => {
                                setRefreshBoardList(refreshBoardList => !refreshBoardList);
                                setOpenModel({
                                    modalType: '',
                                    modalProps: {
                                        data: null,
                                        columnId: null,
                                    },
                                });
                                navigate("/all-projects");
                            },
                            onCancel: () => {
                                setRefreshBoardList(refreshBoardList => !refreshBoardList);
                                console.log("onCancel")
                            },
                            columnId: null,
                            className: "large-Box"
                        },
                    });
                    //setToggleClusterOverviewLogs("Cluster Logs");
                    // navigate("/clusters/cluster/" + data.data.cluster_unique_id, {
                    //     state: {
                    //         data: {
                    //             name: data.data["display_name"],
                    //         },
                    //         from: "/clusters/preview",
                    //     },
                    // });
                }
                else {
                    console.log("Error");
                    toggleAlert("error", "Cluster Creation Failed.", data.message);
                    setIsCreating(false);
                }
            }).catch((err) => {
                console.log("error");
                toggleAlert("error", "Cluster Creation Failed.", err);
                setIsCreating(false);
            })
    }

    const handleCustomise = () => {
        navigate('/clusters/create');
    }

    return (
        <div>
            <CustomModal
                visible={isCreating}
                //onCancel={hideModal}
                footer={null}
                closable={false}
                centered
                maskClosable={false}
                style={{ border: "none", boxShadow: "none" }}
            >
                <div style={{ textAlign: 'center', background: "transparent", border: "none" }}>
                    <Spin size="large" />
                </div>
            </CustomModal>
            <CreateTaskHeader
                // data={securityData}
                buttonContent={"One-click cluster"}
                // values={{editing: true}}
                onCancel={() => {
                    setOpenRightDrawer({
                        drawerType: false,
                        drawerProps: {
                            data: null
                        },
                    });
                }}
            />
                <BillingFormWrapper style={{ width: "100%", height: "100%" }}>
                    <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", marginRight: "10px" }}>
                                <>
                                    <Tooltip
                                        title={!costData.totalCost ? "" : <div style={{ width: "fit-content", height: "inherit" }}>
                                            <div className='estimateCostDiv' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <span className='estimateCostDivText'>Data Transfer</span>
                                                <span>{costData.dataTransferCost}</span>
                                            </div>
                                            <div className='storageCostDiv' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <span className='storageCostDivText'>EKS Cluster</span>
                                                <span>{costData.eksClusterCost}</span>
                                            </div>
                                            <div className='storageCostDiv' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <span className='storageCostDivText'>EC2 Instance Type</span>
                                                <span>{costData.instanceTypeCost}</span>
                                            </div>
                                            <div className='storageCostDiv' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <span className='storageCostDivText'>Load Balancer</span>
                                                <span>{costData.loadBalanceCost}</span>
                                            </div>
                                            <div className='storageCostDiv' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <span className='storageCostDivText'>NAT Gateway</span>
                                                <span>{costData.natGatewayCost}</span>
                                            </div>
                                            <div className='storageCostDiv' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <span className='storageCostDivText'>Storage</span>
                                                <span>{costData.storageCost}</span>
                                            </div>
                                            {/* <div className='storageCostDiv' style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                  <span className='storageCostDivText'>Total Estimated Cost</span>
                                  <span>${pageHeading.totalCost}</span>
                                </div> */}
                                        </div>}
                                        overlayClassName='customClusterCost'
                                        placement='bottom'
                                    // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                    >
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "inherit", backgroundColor: "#D1EDD7", borderRadius: "8px", padding: "7px 8px", marginTop: "120px", marginBottom: "30px", minWidth: "268px" }}>
                                            {!costData.totalCost ? <Spin indicator={<LoadingOutlined style={{ color: "gray" }} />} /> : <><img src={coinIcon} />
                                                <p style={{ fontFamily: "Nunito Sans", fontSize: "14px", fontWeight: "600", color: "#1F1F1F", marginLeft: "10px" }} > {`Total Estimated Cost - ${costData.totalCost}`} </p></>}
                                        </div>
                                    </Tooltip>
                                </>
                            </div>
                            {defaultData.map((val, idx) => {
                                return (<Box style={{ ...boxStyle }}>
                                    <div style={{ display: "flex", width: "90%", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex" }}>
                                            <img src={val.icon} style={{ height: "14px", width: "14px", marginRight: "20px", transform: "translateY(3px)" }} />
                                            <Label style={labelStyle}>{val.fieldName}</Label>
                                        </div>
                                        <p style={valueStyle}>{Array.isArray(val.value) ? val.value.map((x) => x.name).join(", ") : val.value}</p>
                                    </div>
                                </Box>)
                            })}
                            <div style={{ display: "flex", paddingLeft: "10px", cursor: "pointer" }}>
                                <img src={learnMoreImg} style={{ marginRight: "10px" }} />
                                <p style={learnMoreStyle}>Learn More</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <p style={noteStyle}>Note: Select the Create button to create your cluster</p>
                            <p style={noteStyle}>or choose the Customize option to go through every step and customize</p>
                        </div>
                        <div style={{ marginBottom: "100px" }}>
                        </div>
                    </div>
                    {/* <div style={{ height: "100px" }}></div> */}
                </BillingFormWrapper>
            <CreateTaskFooter buttonContent={"One-click cluster"} givenButtonFunction1={submitDetails} givenButtonFunction2={handleCustomise} />
        </div>
    );
}