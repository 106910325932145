import React, { useState, useEffect, useContext } from 'react';
import Select from '../../Deployments/Deployment.styles';
import api from '../../../api'; // Ensure this is the correct path to your API instanc
import Box from '../../../components/utility/box';
import noDataImg from '@iso/assets/images/no-data-found.svg';
import CreateTaskHeader from "../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader";
import { AppContext } from "../../../context/ContextProvider";
import initialData from '../../../redux/scrumBoard/data';
import { SelectOption as Option } from '@iso/components/uielements/select';
import { Form } from 'antd';
import { Input, Spin } from 'antd';
import CreateTaskFooter from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';
import { useDeploymentFeatures } from '../../../containers/Dashboard/CustomHooks/useDeploymentFeatures';
import { CustomModal } from '../../Dashboard/CustomComponents/PageLoader.style';
import CryptoJS from 'crypto-js';
import noDataPlusIcon from '@iso/assets/images/nodata-plus-icon.svg';
import { Link } from 'react-router-dom';
const { regions } = initialData;

const noDataSubTitle = {
  color: "#323232",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  marginLeft: "5px"
};

const BringYourClusterDrawer = ({ visible, onClose }) => {

  const { toggleAlert } = useDeploymentFeatures();
  const [accounts, setAccounts] = useState([]);
  const [selectRegions, setSelectRegions] = useState([]);
  const cloudProviders = ['AWS (EKS)']; //, 'GCP (GKS)', 'Azure (AKS)'
  const [loading, setLoading] = useState(false);
  const { setOpenRightDrawer, refreshBoardList, setRefreshBoardList } = useContext(AppContext);
  const [payload, setPayload] = useState({
    selectedAccount: null,
    selectedCloudProvider: null,
    selectedRegion: null,
    clusterName: '',
    accessKey: "",
    secretKey: ""
  })
  const [encryptedData, setEncryptedData] = useState({
    accessKey: '',
    secretKey: ''
  })
  const [clusterNames, setClusterNames] = useState([]);
  const [clusterListFetching, setClusterListFetching] = useState(false);
  const [form] = Form.useForm();

  const encryptSecrets = (data) => {
    const salt = CryptoJS.lib.WordArray.random(16);
    const staticToken = process.env.REACT_APP_API_ENCRYPT_TOKEN;
    const key = CryptoJS.PBKDF2(staticToken, salt, {
      keySize: 256 / 32,
      iterations: 100000,
      hasher: CryptoJS.algo.SHA256,
    });

    const iv = CryptoJS.lib.WordArray.random(16); // AES-CBC requires 16-byte IV
    const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv, mode: CryptoJS.mode.CBC });

    const combined = salt.concat(iv).concat(encrypted.ciphertext);
    return CryptoJS.enc.Base64.stringify(combined);
  }

  useEffect(() => {
    setClusterNames([]);
    if (payload.selectedRegion) {
      setClusterListFetching(true);
      const fetchClusterNames = async () => {
        try {
          const body = {};
          if (payload.selectedAccount.length > 0 && payload.selectedAccount !== 'fetchUsingCredentials') {
            body.aws_account_id = payload.selectedAccount;
          }
          if (payload.selectedRegion.length > 0) {
            body.region = payload.selectedRegion;
          }
          const res = await api.post(process.env.REACT_APP_API_URI + '/api/cluster/region-based-clusters/', body)
          if (res.data.status) {
            const data = res.data.data;
            console.log('cluster names: ', data.clusters)
                // console.log('Cluster names: ', data);
            setClusterNames(data.clusters)
          }
          else {
            toggleAlert('error', 'Error', 'Error fetching cluster names');
          }
        }
        catch (error) {
          console.log('Error fetching the cluster names: ', error)
          toggleAlert('error', 'Error', 'Something went wrong');
        } finally {
          console.log('finally value:', clusterListFetching);
          setClusterListFetching(false);
        }
      }
      fetchClusterNames();
    }
  }, [payload.selectedRegion])

  useEffect(() => {
    console.log('payload.selectedCloudProvider: ', payload.selectedCloudProvider)
    if (payload.selectedCloudProvider) {
      const fetchAccounts = async () => {
        setLoading(true);
        try {
          const response = await api.post(process.env.REACT_APP_API_URI + '/api/integration/account/list/');
          const data = response.data;

          if (data.status) {
            const cloudProviderMap = {
              'AWS (EKS)': 'aws',
              'GCP (GKS)': 'gcp',
              'Azure (AKS)': 'azure'  // Ensure Azure is handled
            };

            // Determine the value for the selected cloud provider
            console.log('selectedCloudProvider: ', payload.selectedCloudProvider);
            const filVal = cloudProviderMap[payload.selectedCloudProvider];
            console.log('filVal: ', filVal);
            const accounts = data.data
              .filter((account) => [filVal].includes(account.integration_type) && account.status);
            let myAccounts = [];
            if (accounts.length > 0) {
              myAccounts = accounts.map((account) => (
                <Option key={account.account_id} name='selectedAccount' value={account.account_id} data-account-type={account.integration_type}>
                  <img src={account.integration_logo} style={{ width: "18px", height: "18px", marginRight: "10px" }} alt="provider" />
                  <span>{`${account.account_id}${account.alias ? ` (${account.alias})` : ""}`}</span>
                </Option>
              ));
            } else {
              myAccounts.push(
                <Option key="no-linked-account" value='noLinkedAccount' name="selectedAccount" data-alias="" type="integration" style={{ background: "white" }}>
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", height: "fit-content", padding: "3px 0" }}>
                    <img src={noDataImg} alt="No data" />
                    <span>No Linked Account for this Cloud Provider.</span>
                  </div>
                </Option>
              )
            }
            // myAccounts.push(
            //   <Option key="fetchUsingCredentials" name='selectedAccount' value="fetchUsingCredentials" data-account-type='usingCredentials'>
            //     <span>Fetch using credentials</span>
            //   </Option>
            // )
            setAccounts(myAccounts);
          }
        } catch (error) {
          console.error('Error fetching accounts:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchAccounts();
    }
  }, [payload.selectedCloudProvider])


  useEffect(() => {
    if (payload.selectedAccount) {
      // Extract the account type from the selected account option
      const cloudProviderMap = {
        'AWS (EKS)': 'aws',
        'GCP (GKS)': 'gcp',
        'Azure (AKS)': 'azure'  // Ensure Azure is handled
      };
      const accountType = cloudProviderMap[payload.selectedCloudProvider];
      // accounts.find(account => account.props.value === selectedAccount)?.props['data-account-type'];

      if (accountType && regions[accountType]) {
        const regionsForAccount = regions[accountType];
        const myRegions = Object.keys(regionsForAccount).map((child, ind) => (
          <Option key={`reg-${ind}`} name='selectedRegion' value={child} data-displayname={regionsForAccount[child].name}>
            <div style={{ display: "flex", justifyContent: "start", alignItems: "center", width: "100%" }}>
              <span>{`${regionsForAccount[child].name} / ${child}`}</span>
            </div>
          </Option>
        ));

        setSelectRegions(myRegions.length > 0 ? myRegions : [
          <Option key="0" name="region-redirect" data-alias="" type="integration" style={{ background: "white" }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", height: "fit-content", padding: "3px 0" }}>
              <img src={noDataImg} alt="No data" />
              <span>No region available.</span>
            </div>
          </Option>
        ]);
      } else {
        setSelectRegions([
          <Option key="0" name="region-redirect" data-alias="" type="integration" style={{ background: "white" }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", height: "fit-content", padding: "3px 0" }}>
              <img src={noDataImg} alt="No data" />
              <span>No region available.</span>
            </div>
          </Option>
        ]);
      }
    } else {
      setSelectRegions([
        <Option key="0" name="region-redirect" data-alias="" type="integration" style={{ background: "white" }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", height: "fit-content", padding: "3px 0" }}>
            <img src={noDataImg} alt="No data" />
            <span>No region available.</span>
          </div>
        </Option>
      ]);
    }
  }, [payload.selectedAccount, accounts]);

  const bringYourCluster = async () => {
    try {
      await form.validateFields();
      setLoading(true);
      const body = {};
      if (payload.selectedAccount.length > 0 && payload.selectedAccount !== 'fetchUsingCredentials') {
        body.aws_account_id = payload.selectedAccount;
      }
      // if (payload.accessKey.length > 0) {
      //   body.aws_access_key_id = encryptedData.accessKey;
      // }
      // if (payload.secretKey.length > 0) {
      //   body.aws_secret_access_key = encryptedData.secretKey;
      // }
      if (payload.clusterName.length > 0) {
        body.cluster_name = payload.clusterName;
      }
      if (payload.selectedRegion.length > 0) {
        body.region = payload.selectedRegion;
      }
      api.post(process.env.REACT_APP_API_URI + '/api/cluster/bring-your-clusters/', body)
        .then((res) => {
          if (res.data.status) {
            const data = res.data;
            console.log('Cluster brought successfully', data);
            handleCancel();
            toggleAlert('success', 'Success', 'Job to import your cluster has been submitted successfully');
            sessionStorage.removeItem('bycFormValues');
            form.setFieldsValue({
              selectedCloudProvider: null,
              selectedAccount: null,
              selectedRegion: null,
              clusterName: null,
            });
            // setRefreshBoardList(!refreshBoardList);
          }
          else {
            toggleAlert('error', 'Failed', res.data.data.detail);
          }
        }).catch((error) => {
          console.log('Error in bring your cluster: ', error);
          toggleAlert('error', 'Failed', 'Not able to fetch your cluster.');
        }).finally(() =>
          setLoading(false)
        )

    } catch (error) {
      console.log('Error in bring your cluster: ', error);
      // setLoading(false);
    }
  }

  const handleSelectChange = (value, event) => {
    if (event.name === 'selectedCloudProvider') {
      setPayload({ ...payload, selectedAccount: null });
      form.setFieldValue('selectedAccount', null);
      form.setFieldValue('clusterName', null);
      form.setFieldValue('selectedRegion', null);
      setPayload(prevPayload => ({
        ...prevPayload,
        selectedAccount: null,
        clusterName: '',
        selectedRegion: null,
      }));
    }
    if (event.name === 'selectedAccount') {
      setPayload(prevPayload => ({
        ...prevPayload,
        accessKey: '',
        secretKey: '',
        selectedRegion: null,
      }))
      form.setFieldsValue({
        'selectedRegion': null,
        'clusterName': null,
        'accessKey': '',
        'secretKey': ''
      });
      if (value === 'noLinkedAccount') {
        value = null;
      }
      setClusterNames([])
    }
    if (event.name === 'selectedRegion') {
      setPayload(prevPayload => ({
        ...prevPayload,
        clusterName: null
      }));
      form.setFieldValue('clusterName', null);
    }
    if( event.name === 'clusterName' && value === 'noClusterFound' ){
      value = null;
    }
    console.log('select value:', value);
    setPayload({ ...payload, [event.name]: value });
    if (event.name === 'selectedAccount' && value === null) {
      form.setFieldValue('selectedAccount', null);
    }
    if( event.name === 'clusterName' && value === null){
      form.setFieldValue('clusterName', null);
    }
    const updatePayload = {
      selectedCloudProvider: form.getFieldValue('selectedCloudProvider'),
      selectedAccount: form.getFieldValue('selectedAccount'),
      selectedRegion: form.getFieldValue('selectedRegion'),
      clusterName: form.getFieldValue('clusterName'),
    }
    sessionStorage.setItem('bycFormValues', JSON.stringify(updatePayload));
  }

  useEffect(() => {
    let selectedPayload = JSON.parse(sessionStorage.getItem('bycFormValues'))
    if (!selectedPayload){
      selectedPayload = {
        selectedCloudProvider: null,
        selectedAccount: null,
        selectedRegion: null,
        clusterName: null
      }
    }
    setPayload(selectedPayload)
    form.setFieldsValue({
      selectedCloudProvider: selectedPayload?.selectedCloudProvider,
      selectedAccount: selectedPayload?.selectedAccount,
      selectedRegion: selectedPayload?.selectedRegion,
      clusterName: selectedPayload?.clusterName,
    });
  },[])

  const handleChange = (event) => {
    if (event.target.name === 'accessKey' || event.target.name === 'secretKey') {
      setPayload(prevPayload => ({
        ...prevPayload,
        selectedRegion: null,
      }))
      form.setFieldValue('selectedRegion', null);
    }
    setPayload((data) => ({ ...data, [event.target.name]: event.target.value }));
  }

  const handelCancel = () => {
    setOpenRightDrawer({
      drawerType: false,
      drawerProps: {
        data: "",
      },
    });
  }
  const handleCancel = (e) => {
    console.log("clicked handleCancel...");
    handelCancel();
  }
  return (
    <>
      <CustomModal
        visible={loading}
        //onCancel={hideModal}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
        style={{ border: "none", boxShadow: "none", zIndex: loading ? 1100 : -1 }}
      >
        <div style={{ textAlign: 'center', background: "transparent", border: "none" }}>
          <Spin size="large" />
        </div>
      </CustomModal>
      <CreateTaskHeader
        buttonContent={"Bring your cluster"}
        onCancel={() => {
          setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
              data: null
            },
          });
        }}
      />
      <div style={{ padding: "20px 0px 40px 0px" }}>

        <Form
          form={form}
          preserve={false}
          name="dynamic_rule"
        >
          <Box style={{ borderRadius: "20px", position: "relative", marginBottom: "60px", marginTop: "8vh", border: '0px', paddingLeft: '20px' }}>
            <div style={{ width: "96%" }}>
              <Box
                title={<div>
                  <p>Cloud Provider* </p>
                </div>}
                subtitle="Select the cloud provider."
                style={{ border: "transparent", marginBottom: '0', padding: '0' }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Form.Item
                  name="selectedCloudProvider"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your cloud provider.',
                    }
                  ]}
                >
                  <Select
                    style={{ borderRadius: "10px" }}
                    placeholder="Please select the cloud provider"
                    onChange={handleSelectChange}
                    value={payload.selectedCloudProvider}
                  >
                    {cloudProviders.map((provider, idx) => (
                      <Option key={idx} name="selectedCloudProvider" data-alias="" type="cloud-provider" value={provider}>
                        <span>{provider}</span>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div style={{ width: "96%" }}>
              <Box
                title={<div style={{ display: "flex" }}>
                  <p>Target Account* </p>
                </div>}
                subtitle="Select the cloud account where the cluster will be deployed."
                style={{ border: "transparent", marginBottom: "0", padding: '0' }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Form.Item
                  name="selectedAccount"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the target account.',
                    }
                  ]}
                >
                  <Select
                    style={{ borderRadius: "10px" }}
                    placeholder="Please select a Target Account Id"
                    onChange={handleSelectChange}
                    value={payload.selectedAccount}
                    disabled={!payload.selectedCloudProvider || accounts.length === 0}
                  >
                    {accounts.length > 0 ? accounts :
                      <Option key="0" name='selectedAccount' data-alias="" type="integration" style={{ background: "white" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", height: "fit-content", padding: "3px 0" }}>
                          <img src={noDataImg} alt="No data" />
                          <span>No account available.</span>
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Link to='/integration' style={{ textDecoration: 'none', color: 'inherit' }}>
                              <img src={noDataPlusIcon} />
                              <span style={noDataSubTitle}>Create one</span>
                            </Link>
                          </div>
                        </div>
                      </Option>
                    }
                  </Select>
                </Form.Item>
              </div>
            </div>
            {/* {payload.selectedAccount === 'fetchUsingCredentials' && (
              <>
                <div style={{ width: "96%" }}>
                  <Box
                    title={<div>
                      <p>Access Key* </p>
                    </div>}
                    subtitle="Please provide your access key"
                    style={{ border: "transparent", marginBottom: "0", padding: '0' }}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Form.Item
                      name="accessKey"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your access key.',
                        }
                      ]}
                    >
                      <Input
                        placeholder="Enter your access key"
                        value={payload.accessKey}
                        style={{ height: "44px" }}
                        name="accessKey"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div style={{ width: "96%" }}>
                  <Box
                    title={<div>
                      <p>Secret Key* </p>
                    </div>}
                    subtitle="Please provide your secret key"
                    style={{ border: "transparent", marginBottom: '0', padding: '0' }}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Form.Item
                      name="secretKey"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your secret key.',
                        }
                      ]}
                    >
                      <Input
                        placeholder="Enter your secret key"
                        value={payload.secretKey}
                        style={{ height: "44px" }}
                        name="secretKey"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </div>
                </div>
              </>
            )
            } */}

            <div style={{ width: "96%" }}>
              <Box
                title={<div>
                  <p>Region* </p>
                </div>}
                subtitle="Select the region where the cluster will be deployed."
                style={{ border: "transparent", marginBottom: '0', padding: '0' }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Form.Item
                  name="selectedRegion"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your region.',
                    }
                  ]}
                >
                  <Select
                    style={{ borderRadius: "10px" }}
                    placeholder="Please select a Region"
                    value={payload.selectedRegion}
                    onChange={handleSelectChange}
                    disabled={!payload.selectedAccount || (payload.selectedAccount === 'fetchUsingCredentials' ? payload.accessKey.length === 0 || payload.secretKey.length === 0 : false)}
                  >
                    {selectRegions}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div style={{ width: "96%" }}>
              <Box
                title={<div>
                  <p>Cluster Name* </p>
                </div>}
                subtitle="Select the Cluster you want to bring."
                style={{ border: "transparent", marginBottom: '0', padding: '0' }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Form.Item
                  name="clusterName"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your cluster name.',
                    }
                  ]}
                >
                  <Select
                    style={{ borderRadius: "10px" }}
                    placeholder="Please select your cluster"
                    onChange={handleSelectChange}
                    disabled={!payload.selectedRegion || payload.selectedRegion.length < 1} // Disable if no region is selected
                    notFoundContent={clusterListFetching ? <Spin size="small" /> : null}
                  >
                    {console.log('cluster names in return:', clusterNames)}
                    {clusterNames && clusterNames.length > 0 ? clusterNames.map((clusname, idx) => (
                      <Option key={idx} name='clusterName' data-alias="" type='cluster-names' value={clusname}>
                        <span>{clusname}</span>
                      </Option>
                    )) : !clusterListFetching &&
                      <Option key="0" name="clusterName" value='noClusterFound' data-alias="" style={{ background: "white" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", height: "fit-content", padding: "3px 0" }}>
                          <img src={noDataImg} alt="No data" />
                          <span>No cluster available.</span>
                        </div>
                      </Option>
                    }
                  </Select>
                </Form.Item>
              </div>
            </div>
          </Box>
          <CreateTaskFooter buttonContent={"Bring your cluster"} givenButtonFunction1={bringYourCluster} givenButtonFunction2={handleCancel} />
        </Form>
      </div>
    </>
  );
};

export default BringYourClusterDrawer;
