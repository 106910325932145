import { useContext } from "react";
import { AppContext, ContextProvider } from "../../../context/ContextProvider";
import { useLocation } from "react-router-dom";
import api from "../../../api";
import { provisionerStatuses } from "../../../context/helper";

export const useFetchClusterData = () => {
    const { setClusterTabSelected } = useContext(AppContext);
    const { openRightDrawer, setOpenRightDrawer, allClusters, setAllClusters, currentCluster, updateCluster, setUpdateCluster, setCurrentCluster, isUpdate, isOneClick, oneClickCluster, setOneClickCluster, clusterTabSelected, fetchingClusterPrerequisites, setFetchingClusterPrerequisites } = useContext(AppContext);
    let location = useLocation();
    const fetchClusterData = async (isUpdate, isOneClick, id) => {
        let myData = {};
        //if (isUpdate) {
        console.log("MYDATA: ", myData);
        let apiStr = isUpdate ? `${process.env.REACT_APP_API_URI}/api/cluster/${id}` : `${process.env.REACT_APP_API_URI}/api/cluster/one-click-cluster/`;
        await api.get(apiStr).then((res) => {
            let data = res.data;
            if (data.status) {
                console.log("Data: ", data.data.cluster_data.environment);
                data.data.cluster_data.environment === 'default' ? setClusterTabSelected('existingVpc') : setClusterTabSelected(data.data.cluster_data.environment);
                data.data.cluster_data.environment === 'default' ? localStorage.setItem("clusterTabSelected", "existingVpc") : localStorage.setItem("clusterTabSelected", data.data.cluster_data.environment);
                let tags = [];
                tags = data.data.cluster_data.tags ? Object.entries(data.data.cluster_data.tags).map(([name, value]) => ({
                    name: name,
                    value: value
                })) : [{ name: "", value: "" }];
                tags = tags.filter(val => val.name !== "provider");
                tags = tags.length === 0 ? [{ name: "", value: "" }] : tags;
                let selectedAddons = data.data.addons_list.map((val) => {
                    return {
                        id: val.addon_id,
                        name: val.name,
                        subText: val.description,
                        recommended: val.is_recommended,
                        editable: isUpdate ? val.editable : true,
                        dependsOn: [],
                        disabled: isOneClick ? true : !val.editable,
                        selected: true,
                        borderColor: "#f5f5f5",
                        message: ""
                    };
                });
                console.log('Selected addons: ', selectedAddons);
                if (isOneClick || isUpdate) {
                    // localStorage.setItem('no_of_azs', data.data.pre_requsite_data.available_eip_quota);
                    localStorage.setItem('isRegionAvailable', "true");
                }
                if (data.data.cluster_data.environment === "prod") {
                    const pglInfo = data.data.addons_list.find(val => val.name === "PGL stack");
                    const k8sInfo = data.data.addons_list.find(val => val.name === "K8s Dashboard");
                    myData = {
                        id: id,
                        title: data.data.display_name,
                        accountId: data.data.target_account_id,
                        integration: data.data.integration_type,
                        integrationId: isUpdate ? updateCluster.integrationId : data.data.cluster_data.integration_id,
                        alias: data.data.target_account_alias,
                        region: data.data.cluster_data.region,
                        accountType: "",
                        zonesAvailable: [],
                        selectedCluster: data.data,
                        isDataFetched: false,
                        accountType: "aws", //needs to be changed for multiple account types
                        availability_zones: isOneClick ? data.data.pre_requsite_data.availability_zones : [],
                        vpc: "Create New VPC",
                        vpcData: {},
                        prerequisiteInfo: isOneClick ? data.data.pre_requsite_data : {},
                        provisioner: data.data.cluster_data.provisioner,
                        prod: {
                            tags: tags,
                            naz: isOneClick ? [data.data.cluster_data.availability_zones] : data.data.cluster_data.availability_zones,
                            selectedAddons: selectedAddons,
                            publicSubnets: [],
                            privateSubnets: [],
                            subnets: [],
                            vpcCidr: data.data.cluster_data.vpc_cidr_block,
                            flowLogMax: "60",    // flow_log_max_aggregation_interval //Will be edited later
                            logRetention: data.data.cluster_data.vpc_flow_logs === "true" ? data.data.cluster_data.vpc_flow_log_retention_period : "60",    // flow_log_cloudwatch_log_group_retention_in_days
                            clusterLogRetention: data.data.cluster_data.vpc_flow_logs === "true" ? data.data.cluster_data.kubernetes_log_retention_period : "90",    // flow_log_cloudwatch_log_group_retention_in_days
                            endpointPublicAccess: true,
                            endpointPrivateAccess: true,
                            publicAccessCidr: "0.0.0.0/0",
                            versionsAvailable: [],
                            addonsData: {},
                            eks: data.data.cluster_data.kubernetes_version,
                            hostedZones: isUpdate ? [] : oneClickCluster[data.data.cluster_data.environment].hostedZones,
                            serverInsType: "",
                            instanceTypeMin: "",
                            instanceTypeMax: "",
                            backups: false,
                            natGatway: true,
                            vpns: true,
                            vpcFlowLogs: data.data.cluster_data.vpc_flow_logs === "true" ? true : false,    // enable_flow_log
                            enableLogTypes: true,
                            addonIds: {},
                            apiServer: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("api") : false,
                            audit: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("audit") : false,
                            authenticator: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("authenticator") : false,
                            controllerManager: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("controllerManager") : false,
                            scheduler: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("scheduler") : false,
                            addons: {
                                argoFlow: data.data.addons_list.some(val => val.name === "ArgoFlow"),
                                cert_manager_enabled: data.data.addons_list.some(val => val.name === "Cert Manager"),
                                cert_manager_letsencrypt_email: "",
                                cert_manager_install_letsencrypt_http_issuers: false,
                                enable_karpenter: false,
                                karpenter_provisioner_enabled: false,
                                enable_cluster_autoscaler: true,
                                enable_cluster_propotional_autoscaler: false,
                                enable_single_az_ebs_gp3_storage_class: true,
                                create_efs_storage_class: data.data.addons_list.some(val => val.name === "EFS Storage Class"),
                                enable_amazon_eks_aws_ebs_csi_driver: true,
                                enable_amazon_eks_vpc_cni: true,
                                create_service_monitor_crd: true,
                                enable_reloader: data.data.addons_list.some(val => val.name === "Reloader"),
                                enable_metrics_server: true,
                                enable_ingress_nginx: true,
                                enable_external_secrets: false,
                                enable_keda: data.data.addons_list.some(val => val.name === "Keda"),
                                enable_istio: false,
                                enable_aws_node_termination_handler: true,
                                nginx_alb_controler: data.data.addons_list.some(val => val.name === "Ingress ALB Controller"),
                                nginx_ingress_controler: data.data.addons_list.some(val => val.name === "Nginx Ingress Controller"),
                                pgl_stack: pglInfo ? true : false,
                                k8s_dashboard: k8sInfo ? true : false,

                                delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
                            },
                            updateAddons: {
                                pgl_stack: pglInfo ? true : false,
                                // cert_manager_enabled: data.data.addons_list.some(val => val.name === "PGL stack"),
                                // nginx_ingress_controler: data.data.addons_list.some(val => val.name === "PGL stack")
                            },
                            pglStack: {
                                appName: isOneClick ? "" : pglInfo?.value?.app_name,
                                recordPrefix: isOneClick ? "" : pglInfo?.value?.record_prefix,
                                hostname: isOneClick ? "atmosly.in" : pglInfo?.value?.host_name || "atmosly.in",
                                zoneId: isOneClick ? "" : pglInfo?.value?.hosted_zone_id,
                                dnsRecordType: isOneClick ? "" : pglInfo?.value?.dns_record_type,
                                loki: isOneClick ? true : pglInfo?.value?.loki === false ? false : true,
                                cloudWatchCollector: isOneClick ? true : pglInfo?.value?.cloudwatch_collector === false ? false : true,
                                refreshInterval: isOneClick ? "30" : pglInfo?.value?.dashboard_refresh_interval.split("s")[0] || "30",
                                objExpiration: "90",
                                hostedZonesList: [],
                            },
                            k8sDashboard: {
                                dnsRecordType: isOneClick ? "" : k8sInfo?.value?.dns_record_type,
                                hostedZonesList: [],
                                hostname: isOneClick ? "atmosly.in" : k8sInfo?.value?.hosted_zone || "atmosly.in",
                                recordPrefix: isOneClick ? "" : k8sInfo?.value?.record_prefix,
                                zoneId: isOneClick ? "" : k8sInfo?.value?.hosted_zone_id,
                            },
                            argoFlow: {
                                dnsRecordType: isOneClick ? data.data.argoworkflow_specifications.dns_record_type : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.argoworkflow_dns_record_type,
                                hostedZonesList: [],
                                hostname: isOneClick ? data.data.argoworkflow_specifications.hosted_zone : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.argoworkflow_hosted_zone,
                                recordPrefix: isOneClick ? data.data.argoworkflow_specifications.record_prefix : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.argoworkflow_record_prefix,
                                zoneId: isOneClick ? data.data.argoworkflow_specifications.zone_id : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.argoworkflow_zone_id,
                            }
                        },
                        "non-prod": {
                            tags: [{ name: "", value: "" }],
                            naz: [],
                            selectedAddons: [],
                            publicSubnets: [],
                            privateSubnets: [],
                            subnets: [],
                            vpcCidr: "10.0.0.0/16",
                            flowLogMax: "600",     // flow_log_max_aggregation_interval
                            logRetention: "60",     // flow_log_cloudwatch_log_group_retention_in_days
                            clusterLogRetention: "90",  // cluster_log_retention_in_days
                            eks: "",
                            hostedZones: [],
                            serverInsType: "",
                            instanceTypeMin: "",
                            instanceTypeMax: "",
                            endpointPublicAccess: true,
                            endpointPrivateAccess: true,
                            publicAccessCidr: "0.0.0.0/0",
                            versionsAvailable: [],
                            addonsData: {},
                            backups: false,
                            natGatway: true,
                            vpns: true,
                            vpcFlowLogs: false,    // enable_flow_log
                            enableLogTypes: false,
                            addonIds: {},
                            apiServer: false,
                            audit: false,
                            authenticator: false,
                            controllerManager: false,
                            scheduler: false,
                            addons: {
                                argoFlow: data.data.addons_list.some(val => val.name === "ArgoFlow"),
                                cert_manager_enabled: true,
                                cert_manager_letsencrypt_email: "",
                                cert_manager_install_letsencrypt_http_issuers: false,
                                enable_karpenter: false,
                                karpenter_provisioner_enabled: false,
                                enable_cluster_autoscaler: true,
                                enable_cluster_propotional_autoscaler: false,
                                enable_single_az_ebs_gp3_storage_class: true,
                                create_efs_storage_class: false,
                                enable_amazon_eks_aws_ebs_csi_driver: true,
                                enable_amazon_eks_vpc_cni: true,
                                create_service_monitor_crd: true,
                                enable_reloader: false,
                                enable_metrics_server: true,
                                enable_ingress_nginx: true,
                                enable_external_secrets: false,
                                enable_keda: false,
                                enable_istio: false,
                                enable_aws_node_termination_handler: true,
                                nginx_alb_controler: false,
                                nginx_ingress_controler: true,
                                pgl_stack: true,
                                k8s_dashboard: true,

                                delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
                            },
                            updateAddons: {},
                            pglStack: {
                                appName: "",
                                recordPrefix: "",
                                hostname: "atmosly.in",
                                zoneId: "",
                                dnsRecordType: "",
                                loki: true,
                                cloudWatchCollector: false,
                                refreshInterval: "30",
                                objExpiration: "90",
                                hostedZonesList: [],
                            },
                            k8sDashboard: {
                                dnsRecordType: "",
                                hostedZonesList: [],
                                hostname: "atmosly.in",
                                recordPrefix: "",
                                zoneId: "",
                            },
                            argoFlow: {
                                dnsRecordType: "",
                                hostedZonesList: [],
                                hostname: "atmosly.in",
                                recordPrefix: "",
                                zoneId: "",
                            }
                        },
                        dev: {
                            tags: [{ name: "", value: "" }],
                            naz: [],
                            selectedAddons: [],
                            publicSubnets: [],
                            privateSubnets: [],
                            subnets: [],
                            vpcCidr: "",
                            flowLogMax: "0",
                            logRetention: "60",
                            clusterLogRetention: "90",
                            endpointPublicAccess: true,
                            endpointPrivateAccess: true,
                            publicAccessCidr: "0.0.0.0/0",
                            versionsAvailable: [],
                            addonsData: {},
                            eks: "",
                            hostedZones: [],
                            serverInsType: "",
                            instanceTypeMin: "",
                            instanceTypeMax: "",
                            backups: false,
                            natGatway: false,
                            vpns: false,
                            vpcFlowLogs: false,
                            enableLogTypes: false,
                            addonIds: {},
                            apiServer: false,
                            audit: false,
                            authenticator: false,
                            controllerManager: false,
                            scheduler: false,
                            addons: {
                                argoFlow: true,
                                cert_manager_enabled: true,
                                cert_manager_letsencrypt_email: "",
                                cert_manager_install_letsencrypt_http_issuers: false,
                                enable_karpenter: false,
                                karpenter_provisioner_enabled: false,
                                enable_cluster_autoscaler: true,
                                enable_cluster_propotional_autoscaler: false,
                                enable_single_az_ebs_gp3_storage_class: true,
                                create_efs_storage_class: false,
                                enable_amazon_eks_aws_ebs_csi_driver: true,
                                enable_amazon_eks_vpc_cni: true,
                                create_service_monitor_crd: true,
                                enable_reloader: false,
                                enable_metrics_server: true,
                                enable_ingress_nginx: true,
                                enable_external_secrets: false,
                                enable_keda: false,
                                enable_istio: false,
                                enable_aws_node_termination_handler: true,
                                nginx_alb_controler: false,
                                nginx_ingress_controler: true,
                                pgl_stack: false,
                                k8s_dashboard: false,

                                delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
                            },
                            updateAddons: {},
                            pglStack: {
                                appName: "",
                                recordPrefix: "",
                                hostname: "atmosly.in",
                                zoneId: "",
                                dnsRecordType: "",
                                loki: true,
                                cloudWatchCollector: false,
                                refreshInterval: "30",
                                objExpiration: "90",
                                hostedZonesList: [],
                            },
                            k8sDashboard: {
                                dnsRecordType: "",
                                hostedZonesList: [],
                                hostname: "atmosly.in",
                                recordPrefix: "",
                                zoneId: "",
                            },
                            argoFlow: {
                                dnsRecordType: "",
                                hostedZonesList: [],
                                hostname: "atmosly.in",
                                recordPrefix: "",
                                zoneId: "",
                            }
                        },
                        existingVpc: {
                            tags: [{ name: "", value: "" }],
                            selectedAddons: [],
                            naz: [],
                            publicSubnets: [],
                            privateSubnets: [],
                            subnets: [],
                            hostedZones: [],
                            vpcCidr: "10.0.0.0/16",
                            flowLogMax: "60",    // flow_log_max_aggregation_interval
                            logRetention: "60",    // flow_log_cloudwatch_log_group_retention_in_days
                            clusterLogRetention: "90",    // cluster_log_retention_in_days
                            endpointPublicAccess: true,
                            endpointPrivateAccess: true,
                            publicAccessCidr: "0.0.0.0/0",
                            versionsAvailable: [],
                            addonsData: {},
                            eks: "",
                            serverInsType: "",
                            instanceTypeMin: "",
                            instanceTypeMax: "",
                            backups: false,
                            natGatway: true,
                            vpns: true,
                            vpcFlowLogs: true,    // enable_flow_log
                            enableLogTypes: true,
                            addonIds: {},
                            apiServer: false,
                            audit: false,
                            authenticator: false,
                            controllerManager: false,
                            scheduler: false,
                            addons: {
                                cert_manager_enabled: false,
                                cert_manager_letsencrypt_email: "",
                                cert_manager_install_letsencrypt_http_issuers: false,
                                enable_karpenter: false,
                                karpenter_provisioner_enabled: false,
                                enable_cluster_autoscaler: true,
                                enable_cluster_propotional_autoscaler: false,
                                enable_single_az_ebs_gp3_storage_class: true,
                                create_efs_storage_class: false,
                                enable_amazon_eks_aws_ebs_csi_driver: true,
                                enable_amazon_eks_vpc_cni: true,
                                create_service_monitor_crd: true,
                                enable_reloader: false,
                                enable_metrics_server: true,
                                enable_ingress_nginx: true,
                                enable_external_secrets: false,
                                enable_keda: false,
                                enable_istio: false,
                                enable_aws_node_termination_handler: true,
                                nginx_alb_controler: false,
                                nginx_ingress_controler: true,
                                pgl_stack: true,
                                k8s_dashboard: true,
                                argoFlow: true,

                                delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
                            },
                            pglStack: {
                                appName: "",
                                recordPrefix: "",
                                hostname: "atmosly.in",
                                zoneId: "",
                                dnsRecordType: "",
                                loki: true,
                                cloudWatchCollector: true,
                                refreshInterval: "30",
                                objExpiration: "90",
                                hostedZonesList: [],
                            },
                            k8sDashboard: {
                                recordPrefix: "",
                                hostname: "atmosly.in",
                                zoneId: "",
                                dnsRecordType: "",
                                hostedZonesList: [],
                            },
                            argoFlow: {
                                recordPrefix: "",
                                hostname: "atmosly.in",
                                zoneId: "",
                                dnsRecordType: "",
                                hostedZonesList: [],
                            }
                        }
                    };
                }
                else if (data.data.cluster_data.environment === 'default') {
                    const pglInfo = data.data.addons_list.find(val => val.name === "PGL stack");
                    const k8sInfo = data.data.addons_list.find(val => val.name === "K8s Dashboard");
                    console.log("Data for default addons...", selectedAddons);
                    myData = {
                        id: id,
                        title: data.data.display_name,
                        tabSelected: data.data.cluster_data.environment === 'default' ? 'existingVpc' : data.data.cluster_data.environment,
                        accountId: data.data.target_account_id,
                        integration: data.data.integration_type,
                        integrationId: data.data.cluster_data.cloud_account_id,
                        alias: data.data.target_account_alias,
                        region: data.data.cluster_data.region,
                        accountType: "",
                        zonesAvailable: [],
                        selectedCluster: data.data,
                        isDataFetched: false,
                        accountType: "aws", //needs to be changed for multiple account types
                        availability_zones: isOneClick ? data.data.pre_requsite_data.availability_zones : data.data.cluster_data.availability_zones,
                        vpc: data.data.cluster_data.vpc_id,
                        vpcData: {},
                        prerequisiteInfo: isOneClick ? data.data.pre_requsite_data : {},
                        provisioner: data.data.cluster_data.provisioner,
                        prod: {
                            tags: [{ name: "", value: "" }],
                            naz: [],
                            publicSubnets: [],
                            privateSubnets: [],
                            selectedAddons: [],
                            vpcCidr: "10.0.0.0/16",
                            flowLogMax: "60",    // flow_log_max_aggregation_interval
                            logRetention: "60",    // flow_log_cloudwatch_log_group_retention_in_days
                            clusterLogRetention: "90",    // cluster_log_retention_in_days
                            endpointPublicAccess: true,
                            endpointPrivateAccess: true,
                            publicAccessCidr: "0.0.0.0/0",
                            versionsAvailable: [],
                            addonsData: {},
                            eks: "",
                            hostedZones: [],
                            serverInsType: "",
                            instanceTypeMin: "",
                            instanceTypeMax: "",
                            backups: false,
                            natGatway: true,
                            vpns: true,
                            vpcFlowLogs: true,    // enable_flow_log
                            enableLogTypes: true,
                            addonIds: {},
                            apiServer: false,
                            audit: false,
                            authenticator: false,
                            controllerManager: false,
                            scheduler: false,
                            addons: {
                                argoFlow: true,
                                cert_manager_enabled: true,
                                cert_manager_letsencrypt_email: "",
                                cert_manager_install_letsencrypt_http_issuers: false,
                                enable_karpenter: false,
                                karpenter_provisioner_enabled: false,
                                enable_cluster_autoscaler: true,
                                enable_cluster_propotional_autoscaler: false,
                                enable_single_az_ebs_gp3_storage_class: true,
                                create_efs_storage_class: false,
                                enable_amazon_eks_aws_ebs_csi_driver: true,
                                enable_amazon_eks_vpc_cni: true,
                                create_service_monitor_crd: true,
                                enable_reloader: false,
                                enable_metrics_server: true,
                                enable_ingress_nginx: true,
                                enable_external_secrets: false,
                                enable_keda: false,
                                enable_istio: false,
                                enable_aws_node_termination_handler: true,
                                nginx_alb_controler: false,
                                nginx_ingress_controler: true,
                                pgl_stack: true,
                                k8s_dashboard: false,

                                delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
                            },
                            updateAddons: {},
                            pglStack: {
                                appName: "",
                                recordPrefix: "",
                                hostname: "atmosly.in",
                                zoneId: "",
                                dnsRecordType: "",
                                loki: true,
                                cloudWatchCollector: true,
                                refreshInterval: "30",
                                objExpiration: "90",
                                hostedZonesList: [],
                            },
                            k8sDashboard: {
                                dnsRecordType: "",
                                hostedZonesList: [],
                                hostname: "atmosly.in",
                                recordPrefix: "",
                                zoneId: "",
                            },
                            argoFlow: {
                                dnsRecordType: "",
                                hostedZonesList: [],
                                hostname: "atmosly.in",
                                recordPrefix: "",
                                zoneId: "",
                            }
                        },
                        "non-prod": {
                            tags: tags,
                            naz: isOneClick ? [data.data.cluster_data.availability_zones] : data.data.cluster_data.availability_zones,
                            selectedAddons: selectedAddons,
                            publicSubnets: [],
                            privateSubnets: [],
                            subnets: [],
                            vpcCidr: data.data.cluster_data.vpc_cidr_block,
                            flowLogMax: "60",     // flow_log_max_aggregation_interval
                            logRetention: data.data.cluster_data.vpc_flow_logs === "true" ? data.data.cluster_data.vpc_flow_log_retention_period : "60",    // flow_log_cloudwatch_log_group_retention_in_days
                            clusterLogRetention: data.data.cluster_data.vpc_flow_logs === "true" ? data.data.cluster_data.kubernetes_log_retention_period : "90",    // flow_log_cloudwatch_log_group_retention_in_days
                            eks: data.data.cluster_data.kubernetes_version,
                            hostedZones: isUpdate ? [] : oneClickCluster[data.data.cluster_data.environment].hostedZones,
                            serverInsType: "",
                            instanceTypeMin: "",
                            instanceTypeMax: "",
                            endpointPublicAccess: true,
                            endpointPrivateAccess: true,
                            publicAccessCidr: "0.0.0.0/0",
                            versionsAvailable: [],
                            addonsData: {},
                            backups: false,
                            natGatway: true,
                            vpns: true,
                            vpcFlowLogs: data.data.cluster_data.vpc_flow_logs === "true" ? true : false,    // enable_flow_log
                            enableLogTypes: false,
                            addonIds: {},
                            apiServer: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("api_server") : false,
                            audit: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("audit") : false,
                            authenticator: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("authenticator") : false,
                            controllerManager: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("controller_manager") : false,
                            scheduler: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("scheduler") : false,
                            addons: {
                                argoFlow: data.data.addons_list.some(val => val.name === "ArgoFlow"),
                                cert_manager_enabled: data.data.addons_list.some(val => val.name === "Cert Manager"),
                                cert_manager_letsencrypt_email: "",
                                cert_manager_install_letsencrypt_http_issuers: false,
                                enable_karpenter: false,
                                karpenter_provisioner_enabled: false,
                                enable_cluster_autoscaler: true,
                                enable_cluster_propotional_autoscaler: false,
                                enable_single_az_ebs_gp3_storage_class: true,
                                create_efs_storage_class: data.data.addons_list.some(val => val.name === "EFS Storage Class"),
                                enable_amazon_eks_aws_ebs_csi_driver: true,
                                enable_amazon_eks_vpc_cni: true,
                                create_service_monitor_crd: true,
                                enable_reloader: data.data.addons_list.some(val => val.name === "Reloader"),
                                enable_metrics_server: true,
                                enable_ingress_nginx: true,
                                enable_external_secrets: false,
                                enable_keda: data.data.addons_list.some(val => val.name === "Keda"),
                                enable_istio: false,
                                enable_aws_node_termination_handler: true,
                                nginx_alb_controler: data.data.addons_list.some(val => val.name === "Ingress ALB Controller"),
                                nginx_ingress_controler: data.data.addons_list.some(val => val.name === "Nginx Ingress Controller"),
                                pgl_stack: data.data.addons_list.some(val => val.name === "PGL stack"),
                                k8s_dashboard: data.data.addons_list.some(val => val.name === "K8s Dashboard"),

                                delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
                            },
                            updateAddons: {
                                pgl_stack: data.data.addons_list.some(val => val.name === "PGL stack"),
                            },
                            pglStack: {
                                appName: "",
                                recordPrefix: "",
                                hostname: "atmosly.in",
                                zoneId: "",
                                dnsRecordType: "",
                                loki: true,
                                cloudWatchCollector: false,
                                refreshInterval: "30",
                                objExpiration: "90",
                                hostedZonesList: [],
                            },
                            k8sDashboard: {
                                dnsRecordType: "",
                                hostedZonesList: [],
                                hostname: "atmosly.in",
                                recordPrefix: "",
                                zoneId: "",
                            },
                            argoFlow: {
                                dnsRecordType: isOneClick ? data.data.argoworkflow_specifications.dns_record_type : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.dns_record_type,
                                hostedZonesList: [],
                                hostname: isOneClick ? data.data.argoworkflow_specifications.hosted_zone : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.dns_record_type,
                                recordPrefix: isOneClick ? data.data.argoworkflow_specifications.record_prefix : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.dns_record_type,
                                zoneId: isOneClick ? data.data.argoworkflow_specifications.zone_id : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.dns_record_type,
                            }
                        },
                        dev: {
                            tags: [{ name: "", value: "" }],
                            naz: [],
                            selectedAddons: [],
                            publicSubnets: [],
                            privateSubnets: [],
                            vpcCidr: "",
                            flowLogMax: "0",
                            logRetention: "60",
                            clusterLogRetention: "90",
                            endpointPublicAccess: true,
                            endpointPrivateAccess: true,
                            publicAccessCidr: "0.0.0.0/0",
                            versionsAvailable: [],
                            addonsData: {},
                            eks: "",
                            hostedZones: [],
                            serverInsType: "",
                            instanceTypeMin: "",
                            instanceTypeMax: "",
                            backups: false,
                            natGatway: false,
                            vpns: false,
                            vpcFlowLogs: false,
                            enableLogTypes: false,
                            addonIds: {},
                            apiServer: false,
                            audit: false,
                            authenticator: false,
                            controllerManager: false,
                            scheduler: false,
                            addons: {
                                argoFlow: true,
                                cert_manager_enabled: true,
                                cert_manager_letsencrypt_email: "",
                                cert_manager_install_letsencrypt_http_issuers: false,
                                enable_karpenter: false,
                                karpenter_provisioner_enabled: false,
                                enable_cluster_autoscaler: true,
                                enable_cluster_propotional_autoscaler: false,
                                enable_single_az_ebs_gp3_storage_class: true,
                                create_efs_storage_class: false,
                                enable_amazon_eks_aws_ebs_csi_driver: true,
                                enable_amazon_eks_vpc_cni: true,
                                create_service_monitor_crd: true,
                                enable_reloader: false,
                                enable_metrics_server: true,
                                enable_ingress_nginx: true,
                                enable_external_secrets: false,
                                enable_keda: false,
                                enable_istio: false,
                                enable_aws_node_termination_handler: true,
                                nginx_alb_controler: false,
                                nginx_ingress_controler: true,
                                pgl_stack: false,
                                k8sDashboard: false
                            },
                            updateAddons: {},
                            pglStack: {
                                appName: "",
                                hostname: "",
                                loki: false,
                                cloudWatchCollector: false,
                                hostname: "",
                                objExpiration: "",
                                hostedZonesList: [],
                            },
                            k8sDashboard: {
                                dnsRecordType: "",
                                hostedZonesList: [],
                                hostname: "atmosly.in",
                                recordPrefix: "",
                                zoneId: "",
                            },
                            argoFlow: {
                                dnsRecordType: "",
                                hostedZonesList: [],
                                hostname: "atmosly.in",
                                recordPrefix: "",
                                zoneId: "",
                            }
                        },
                        existingVpc: {
                            tags: Object.entries(data.data.cluster_data.tags).map(([key, value]) => ({
                                name: key,
                                value: value
                            })),
                            selectedAddons: selectedAddons,
                            naz: data.data.cluster_data.availability_zones,
                            publicSubnets: [],
                            privateSubnets: data.data.cluster_data.private_subnet_ids,
                            subnets: data.data.cluster_data.private_subnet_ids,
                            hostedZones: [],
                            vpcCidr: "10.0.0.0/16",
                            flowLogMax: "60",    // flow_log_max_aggregation_interval
                            logRetention: "60",    // flow_log_cloudwatch_log_group_retention_in_days
                            clusterLogRetention: "90",    // cluster_log_retention_in_days
                            endpointPublicAccess: true,
                            endpointPrivateAccess: true,
                            publicAccessCidr: "0.0.0.0/0",
                            versionsAvailable: [],
                            addonsData: {},
                            eks: data.data.cluster_data.kubernetes_version,
                            cluster_version_id: data.data.cluster_data.cluster_version_id,
                            serverInsType: "",
                            instanceTypeMin: "",
                            instanceTypeMax: "",
                            backups: false,
                            natGatway: true,
                            vpns: true,
                            vpcFlowLogs: false,    // enable_flow_log
                            enableLogTypes: true,
                            addonIds: {},
                            apiServer: false,
                            audit: false,
                            authenticator: false,
                            controllerManager: false,
                            scheduler: false,
                            addons: {
                                argoFlow: data.data.addons_list.some(val => val.name === "ArgoFlow"),
                                cert_manager_enabled: data.data.addons_list.some(val => val.name === "Cert Manager"),
                                cert_manager_letsencrypt_email: "",
                                cert_manager_install_letsencrypt_http_issuers: false,
                                enable_karpenter: false,
                                karpenter_provisioner_enabled: false,
                                enable_cluster_autoscaler: true,
                                enable_cluster_propotional_autoscaler: false,
                                enable_single_az_ebs_gp3_storage_class: true,
                                create_efs_storage_class: data.data.addons_list.some(val => val.name === "EFS Storage Class"),
                                enable_amazon_eks_aws_ebs_csi_driver: true,
                                enable_amazon_eks_vpc_cni: true,
                                create_service_monitor_crd: true,
                                enable_reloader: data.data.addons_list.some(val => val.name === "Reloader"),
                                enable_metrics_server: true,
                                enable_ingress_nginx: true,
                                enable_external_secrets: false,
                                enable_keda: data.data.addons_list.some(val => val.name === "Keda"),
                                enable_istio: false,
                                enable_aws_node_termination_handler: true,
                                nginx_alb_controler: data.data.addons_list.some(val => val.name === "Ingress ALB Controller"),
                                nginx_ingress_controler: data.data.addons_list.some(val => val.name === "Nginx Ingress Controller"),
                                pgl_stack: pglInfo ? true : false,
                                k8s_dashboard: k8sInfo ? true : false,

                                delete_k8s_dashboard: false
                                // cert_manager_enabled: false,
                                // cert_manager_letsencrypt_email: "",
                                // cert_manager_install_letsencrypt_http_issuers: false,
                                // enable_karpenter: false,
                                // karpenter_provisioner_enabled: false,
                                // enable_cluster_autoscaler: true,
                                // enable_cluster_propotional_autoscaler: false,
                                // enable_single_az_ebs_gp3_storage_class: true,
                                // create_efs_storage_class: false,
                                // enable_amazon_eks_aws_ebs_csi_driver: true,
                                // enable_amazon_eks_vpc_cni: true,
                                // create_service_monitor_crd: true,
                                // enable_reloader: false,
                                // enable_metrics_server: true,
                                // enable_ingress_nginx: true,
                                // enable_external_secrets: false,
                                // enable_keda: false,
                                // enable_istio: false,
                                // enable_aws_node_termination_handler: true,
                                // nginx_alb_controler: false,
                                // nginx_ingress_controler: true,
                                // pgl_stack: true,
                                // k8s_dashboard: true,
                                // argoFlow: true,

                                // delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
                            },
                            updateAddons: {
                                pgl_stack: pglInfo ? true : false,
                            },
                            pglStack: {
                                appName: isOneClick ? "" : pglInfo?.value?.name || "pgl-stack",
                                recordPrefix: isOneClick ? "" : pglInfo?.value?.dns_record_id || "pgl-stack",
                                hostname: isOneClick ? "atmosly.in" : pglInfo?.value?.dns_record_id || "atmosly.in",
                                zoneId: isOneClick ? "" : pglInfo?.value?.zone_id,
                                dnsRecordType: isOneClick ? "" : pglInfo?.value?.dns_record_type || 'cloudflare',
                                loki: isOneClick ? true : pglInfo?.value?.loki || "true",
                                cloudWatchCollector: isOneClick ? true : pglInfo?.value?.cloudwatch_collector || "false",
                                refreshInterval: isOneClick ? "30" : pglInfo?.value?.dashboard_refresh_interval.split("s")[0] || "30",
                                objExpiration: "90",
                                hostedZonesList: [],
                            },
                            k8sDashboard: {
                                dnsRecordType: isOneClick ? "" : k8sInfo?.value?.dns_record_type || 'cloudflare',
                                hostedZonesList: [],
                                hostname: isOneClick ? "atmosly.in" : k8sInfo?.value?.dns_record_id || 'atmosly.in',
                                recordPrefix: isOneClick ? "" : k8sInfo?.value?.record_prefix || "k8s-dashboard",
                                zoneId: isOneClick ? "" : k8sInfo?.value?.hosted_zone_id,
                            },
                            argoFlow: {
                                dnsRecordType: isOneClick ? data.data.argoworkflow_specifications.dns_record_type : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.argoworkflow_dns_record_type || "cloudflare",
                                hostedZonesList: [],
                                hostname: isOneClick ? data.data.argoworkflow_specifications.hosted_zone : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.argoworkflow_hosted_zone || "atmosly.in",
                                recordPrefix: isOneClick ? data.data.argoworkflow_specifications.record_prefix : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.argoworkflow_record_prefix || "argoworkflow",
                                zoneId: isOneClick ? data.data.argoworkflow_specifications.zone_id : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.argoworkflow_zone_id,
                            }
                        }
                    };
                    // myData=selectedAddons
                    console.log('mydata in use: ', myData);
                }
                else {
                    const pglInfo = data.data.addons_list.find(val => val.name === "PGL stack");
                    const k8sInfo = data.data.addons_list.find(val => val.name === "K8s Dashboard");
                    myData = {
                        id: id,
                        title: data.data.display_name,
                        accountId: data.data.target_account_id,
                        integration: data.data.integration_type,
                        integrationId: isUpdate ? updateCluster.integrationId : data.data.cluster_data.integration_id,
                        alias: data.data.target_account_alias,
                        region: data.data.cluster_data.region,
                        accountType: "",
                        zonesAvailable: [],
                        selectedCluster: data.data,
                        isDataFetched: false,
                        accountType: "aws", //needs to be changed for multiple account types
                        availability_zones: isOneClick ? data.data.pre_requsite_data.availability_zones : [],
                        vpc: "Create New VPC",
                        vpcData: {},
                        prerequisiteInfo: isOneClick ? data.data.pre_requsite_data : {},
                        provisioner: data.data.cluster_data.provisioner,
                        prod: {
                            tags: [{ name: "", value: "" }],
                            naz: [],
                            selectedAddons: [],
                            vpcCidr: "10.0.0.0/16",
                            publicSubnets: [],
                            privateSubnets: [],
                            subnets: [],
                            flowLogMax: "60",    // flow_log_max_aggregation_interval
                            logRetention: "60",    // flow_log_cloudwatch_log_group_retention_in_days
                            clusterLogRetention: "90",    // cluster_log_retention_in_days
                            endpointPublicAccess: true,
                            endpointPrivateAccess: true,
                            publicAccessCidr: "0.0.0.0/0",
                            versionsAvailable: [],
                            addonsData: {},
                            eks: "",
                            hostedZones: [],
                            serverInsType: "",
                            instanceTypeMin: "",
                            instanceTypeMax: "",
                            backups: false,
                            natGatway: true,
                            vpns: true,
                            vpcFlowLogs: true,    // enable_flow_log
                            enableLogTypes: true,
                            addonIds: {},
                            apiServer: false,
                            audit: false,
                            authenticator: false,
                            controllerManager: false,
                            scheduler: false,
                            addons: {
                                argoFlow: true,
                                cert_manager_enabled: true,
                                cert_manager_letsencrypt_email: "",
                                cert_manager_install_letsencrypt_http_issuers: false,
                                enable_karpenter: false,
                                karpenter_provisioner_enabled: false,
                                enable_cluster_autoscaler: true,
                                enable_cluster_propotional_autoscaler: false,
                                enable_single_az_ebs_gp3_storage_class: true,
                                create_efs_storage_class: false,
                                enable_amazon_eks_aws_ebs_csi_driver: true,
                                enable_amazon_eks_vpc_cni: true,
                                create_service_monitor_crd: true,
                                enable_reloader: false,
                                enable_metrics_server: true,
                                enable_ingress_nginx: true,
                                enable_external_secrets: false,
                                enable_keda: false,
                                enable_istio: false,
                                enable_aws_node_termination_handler: true,
                                nginx_alb_controler: false,
                                nginx_ingress_controler: true,
                                pgl_stack: true,
                                k8s_dashboard: false,

                                delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
                            },
                            updateAddons: {},
                            pglStack: {
                                appName: "",
                                recordPrefix: "",
                                hostname: "atmosly.in",
                                zoneId: "",
                                dnsRecordType: "",
                                loki: true,
                                cloudWatchCollector: true,
                                refreshInterval: "30",
                                objExpiration: "90",
                                hostedZonesList: [],
                            },
                            k8sDashboard: {
                                dnsRecordType: "",
                                hostedZonesList: [],
                                hostname: "atmosly.in",
                                recordPrefix: "",
                                zoneId: "",
                            },
                            argoFlow: {
                                dnsRecordType: "",
                                hostedZonesList: [],
                                hostname: "atmosly.in",
                                recordPrefix: "",
                                zoneId: "",
                            }
                        },
                        "non-prod": {
                            tags: tags,
                            naz: isOneClick ? [data.data.cluster_data.availability_zones] : data.data.cluster_data.availability_zones,
                            selectedAddons: selectedAddons,
                            publicSubnets: [],
                            privateSubnets: [],
                            subnets: [],
                            vpcCidr: data.data.cluster_data.vpc_cidr_block,
                            flowLogMax: "60",     // flow_log_max_aggregation_interval
                            logRetention: data.data.cluster_data.vpc_flow_logs === "true" ? data.data.cluster_data.vpc_flow_log_retention_period : "60",    // flow_log_cloudwatch_log_group_retention_in_days
                            clusterLogRetention: data.data.cluster_data.vpc_flow_logs === "true" ? data.data.cluster_data.kubernetes_log_retention_period : "90",    // flow_log_cloudwatch_log_group_retention_in_days
                            eks: data.data.cluster_data.kubernetes_version,
                            hostedZones: isUpdate ? [] : oneClickCluster[data.data.cluster_data.environment].hostedZones,
                            serverInsType: "",
                            instanceTypeMin: "",
                            instanceTypeMax: "",
                            endpointPublicAccess: true,
                            endpointPrivateAccess: true,
                            publicAccessCidr: "0.0.0.0/0",
                            versionsAvailable: [],
                            addonsData: {},
                            backups: false,
                            natGatway: true,
                            vpns: true,
                            vpcFlowLogs: data.data.cluster_data.vpc_flow_logs === "true" ? true : false,    // enable_flow_log
                            enableLogTypes: false,
                            addonIds: {},
                            apiServer: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("api_server") : false,
                            audit: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("audit") : false,
                            authenticator: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("authenticator") : false,
                            controllerManager: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("controllerManager") : false,
                            scheduler: isOneClick ? false : data.data.cluster_data.cluster_enabled_log_types ? data.data.cluster_data.cluster_enabled_log_types.includes("scheduler") : false,
                            addons: {
                                argoFlow: data.data.addons_list.some(val => val.name === "ArgoFlow"),
                                cert_manager_enabled: data.data.addons_list.some(val => val.name === "Cert Manager"),
                                cert_manager_letsencrypt_email: "",
                                cert_manager_install_letsencrypt_http_issuers: false,
                                enable_karpenter: false,
                                karpenter_provisioner_enabled: false,
                                enable_cluster_autoscaler: true,
                                enable_cluster_propotional_autoscaler: false,
                                enable_single_az_ebs_gp3_storage_class: true,
                                create_efs_storage_class: data.data.addons_list.some(val => val.name === "EFS Storage Class"),
                                enable_amazon_eks_aws_ebs_csi_driver: true,
                                enable_amazon_eks_vpc_cni: true,
                                create_service_monitor_crd: true,
                                enable_reloader: data.data.addons_list.some(val => val.name === "Reloader"),
                                enable_metrics_server: true,
                                enable_ingress_nginx: true,
                                enable_external_secrets: false,
                                enable_keda: data.data.addons_list.some(val => val.name === "Keda"),
                                enable_istio: false,
                                enable_aws_node_termination_handler: true,
                                nginx_alb_controler: data.data.addons_list.some(val => val.name === "Ingress ALB Controller"),
                                nginx_ingress_controler: data.data.addons_list.some(val => val.name === "Nginx Ingress Controller"),
                                pgl_stack: pglInfo ? true : false,
                                k8s_dashboard: k8sInfo ? true : false,

                                delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
                            },
                            updateAddons: {
                                pgl_stack: pglInfo ? true : false,
                            },
                            pglStack: {
                                appName: isOneClick ? "" : pglInfo?.value?.app_name,
                                recordPrefix: isOneClick ? "" : pglInfo?.value?.record_prefix,
                                hostname: isOneClick ? "atmosly.in" : pglInfo?.value?.host_name || "atmosly.in",
                                zoneId: isOneClick ? "" : pglInfo?.value?.hosted_zone_id,
                                dnsRecordType: isOneClick ? "" : pglInfo?.value?.dns_record_type,
                                loki: isOneClick ? true : pglInfo?.value?.loki === false ? false : true,
                                cloudWatchCollector: isOneClick ? true : pglInfo?.value?.cloudwatch_collector === false ? false : true,
                                refreshInterval: isOneClick ? "30" : pglInfo?.value?.dashboard_refresh_interval.split("s")[0] || "30",
                                objExpiration: "90",
                                hostedZonesList: [],
                            },
                            k8sDashboard: {
                                dnsRecordType: isOneClick ? "" : k8sInfo?.value?.dns_record_type,
                                hostedZonesList: [],
                                hostname: isOneClick ? "atmosly.in" : k8sInfo?.value?.hosted_zone || "atmosly.in",
                                recordPrefix: isOneClick ? "" : k8sInfo?.value?.record_prefix,
                                zoneId: isOneClick ? "" : k8sInfo?.value?.hosted_zone_id,
                            },
                            argoFlow: {
                                dnsRecordType: isOneClick ? data.data.argoworkflow_specifications.dns_record_type : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.argoworkflow_dns_record_type,
                                hostedZonesList: [],
                                hostname: isOneClick ? data.data.argoworkflow_specifications.hosted_zone : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.argoworkflow_hosted_zone,
                                recordPrefix: isOneClick ? data.data.argoworkflow_specifications.record_prefix : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.argoworkflow_record_prefix,
                                zoneId: isOneClick ? data.data.argoworkflow_specifications.zone_id : data.data.addons_list.find((val) => val.name === "ArgoFlow")?.value?.argoworkflow_zone_id,
                            }
                        },
                        dev: {
                            tags: [{ name: "", value: "" }],
                            naz: [],
                            selectedAddons: [],
                            publicSubnets: [],
                            privateSubnets: [],
                            subnets: [],
                            vpcCidr: "",
                            flowLogMax: "0",
                            logRetention: "60",
                            clusterLogRetention: "90",
                            endpointPublicAccess: true,
                            endpointPrivateAccess: true,
                            publicAccessCidr: "0.0.0.0/0",
                            versionsAvailable: [],
                            addonsData: {},
                            eks: "",
                            hostedZones: [],
                            serverInsType: "",
                            instanceTypeMin: "",
                            instanceTypeMax: "",
                            backups: false,
                            natGatway: false,
                            vpns: false,
                            vpcFlowLogs: false,
                            enableLogTypes: false,
                            addonIds: {},
                            apiServer: false,
                            audit: false,
                            authenticator: false,
                            controllerManager: false,
                            scheduler: false,
                            addons: {
                                argoFlow: true,
                                cert_manager_enabled: true,
                                cert_manager_letsencrypt_email: "",
                                cert_manager_install_letsencrypt_http_issuers: false,
                                enable_karpenter: false,
                                karpenter_provisioner_enabled: false,
                                enable_cluster_autoscaler: true,
                                enable_cluster_propotional_autoscaler: false,
                                enable_single_az_ebs_gp3_storage_class: true,
                                create_efs_storage_class: false,
                                enable_amazon_eks_aws_ebs_csi_driver: true,
                                enable_amazon_eks_vpc_cni: true,
                                create_service_monitor_crd: true,
                                enable_reloader: false,
                                enable_metrics_server: true,
                                enable_ingress_nginx: true,
                                enable_external_secrets: false,
                                enable_keda: false,
                                enable_istio: false,
                                enable_aws_node_termination_handler: true,
                                nginx_alb_controler: false,
                                nginx_ingress_controler: true,
                                pgl_stack: false,
                                k8sDashboard: false
                            },
                            updateAddons: {},
                            pglStack: {
                                appName: "",
                                hostname: "",
                                loki: false,
                                cloudWatchCollector: false,
                                hostname: "",
                                objExpiration: "",
                                hostedZonesList: [],
                            },
                            k8sDashboard: {
                                dnsRecordType: "",
                                hostedZonesList: [],
                                hostname: "atmosly.in",
                                recordPrefix: "",
                                zoneId: "",
                            },
                            argoFlow: {
                                dnsRecordType: "",
                                hostedZonesList: [],
                                hostname: "atmosly.in",
                                recordPrefix: "",
                                zoneId: "",
                            }
                        },
                        existingVpc: {
                            tags: [{ name: "", value: "" }],
                            selectedAddons: [],
                            naz: [],
                            publicSubnets: [],
                            privateSubnets: [],
                            subnets: [],
                            hostedZones: [],
                            vpcCidr: "10.0.0.0/16",
                            flowLogMax: "60",    // flow_log_max_aggregation_interval
                            logRetention: "60",    // flow_log_cloudwatch_log_group_retention_in_days
                            clusterLogRetention: "90",    // cluster_log_retention_in_days
                            endpointPublicAccess: true,
                            endpointPrivateAccess: true,
                            publicAccessCidr: "0.0.0.0/0",
                            versionsAvailable: [],
                            addonsData: {},
                            eks: "",
                            serverInsType: "",
                            instanceTypeMin: "",
                            instanceTypeMax: "",
                            backups: false,
                            natGatway: true,
                            vpns: true,
                            vpcFlowLogs: true,    // enable_flow_log
                            enableLogTypes: true,
                            addonIds: {},
                            apiServer: false,
                            audit: false,
                            authenticator: false,
                            controllerManager: false,
                            scheduler: false,
                            addons: {
                                cert_manager_enabled: false,
                                cert_manager_letsencrypt_email: "",
                                cert_manager_install_letsencrypt_http_issuers: false,
                                enable_karpenter: false,
                                karpenter_provisioner_enabled: false,
                                enable_cluster_autoscaler: true,
                                enable_cluster_propotional_autoscaler: false,
                                enable_single_az_ebs_gp3_storage_class: true,
                                create_efs_storage_class: false,
                                enable_amazon_eks_aws_ebs_csi_driver: true,
                                enable_amazon_eks_vpc_cni: true,
                                create_service_monitor_crd: true,
                                enable_reloader: false,
                                enable_metrics_server: true,
                                enable_ingress_nginx: true,
                                enable_external_secrets: false,
                                enable_keda: false,
                                enable_istio: false,
                                enable_aws_node_termination_handler: true,
                                nginx_alb_controler: false,
                                nginx_ingress_controler: true,
                                pgl_stack: true,
                                k8s_dashboard: true,
                                argoFlow: true,

                                delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
                            },
                            pglStack: {
                                appName: "",
                                recordPrefix: "",
                                hostname: "atmosly.in",
                                zoneId: "",
                                dnsRecordType: "",
                                loki: true,
                                cloudWatchCollector: true,
                                refreshInterval: "30",
                                objExpiration: "90",
                                hostedZonesList: [],
                            },
                            k8sDashboard: {
                                recordPrefix: "",
                                hostname: "atmosly.in",
                                zoneId: "",
                                dnsRecordType: "",
                                hostedZonesList: [],
                            },
                            argoFlow: {
                                recordPrefix: "",
                                hostname: "atmosly.in",
                                zoneId: "",
                                dnsRecordType: "",
                                hostedZonesList: [],
                            }
                        }
                    };
                }
                console.log("Updated myData: ", myData);
                //return myData;
            }
            else {
                throw new Error(data.error_details);
            }
        }).catch((err) => {
            console.log("Error", err);
            throw err;
            //return {};
        });
        console.log("My data: ", myData);
        return myData;
    }
    //}
    const fetchAddons = async (myData) => {
        if (myData.provisioner !== 'imported') {
            return;
        }
        let tabSelected = myData.tabSelected;
        console.log('tab selected: ', tabSelected);
        let result = myData[tabSelected].cluster_version_id;
        await api.get(process.env.REACT_APP_API_URI + `/api/cluster/cluster-addons/${myData.integration}/${result}/?cluster_id=${myData.id}/`)
            .then(async (res) => {
                let resData = await res.data;
                if (resData.data) {
                    myData[tabSelected]["addonsData"] = resData;
                    console.log('my cluster data end: ', myData);
                    setUpdateCluster({ ...myData, [tabSelected]: myData[tabSelected] });
                    localStorage.setItem('updateCluster', JSON.stringify(myData))
                }
                console.log("This is the addons data: ", resData);
            })
            .catch((err) => {
                console.log("Something went wrong in cluster-addons api :", err);
                // myClusterData[tabSelected]["addonsData"] = { data: {} };
                if (isUpdate) {
                    setUpdateCluster({ ...myData, [tabSelected]: myData[tabSelected] });
                }
            });
    }
    return { fetchClusterData, fetchAddons };
}
